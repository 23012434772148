import React from "react"
import useAuth from "./AuthService"
import PageSpinner from "app/common/PageSpinner"
import { useUniversalCtx } from "app/lib/universal-context"
import Router from "next/router"

const CallbackRoute = () => {
  const auth = useAuth()
  const { url } = useUniversalCtx()

  if (process.browser) {
    if (/access_token|id_token|error/.test(url.hash)) {
      auth.handleAuthentication().then(
        () => {
          Router.replace(auth.popLastAccessedPath() || `/`)
        },
        () => auth.login()
      )
    } else {
      auth.login()
    }
  }
  return <PageSpinner />
}

export default CallbackRoute
