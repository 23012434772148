import React, { useContext } from "react"
import { gql } from "@apollo/client"
import moment from "moment"
import "moment-timezone"
import Query from "../Query"

const Context = React.createContext()

export const QUERY = gql`
  query GetOrganization($organizationSlug: String!) {
    organization(slug: $organizationSlug) {
      id
      timezone
      name
      shortName
      productNames
      logoUrl
      slug
      isChildless
      timezone
      features {
        all
        emenuChoice
      }
    }
  }
`

function Provider({ organizationSlug, children }) {
  if (!organizationSlug) return children

  return (
    <Query
      query={QUERY}
      variables={{ organizationSlug }}
      fetchPolicy="cache-first"
    >
      {({ data }) => {
        if (!data || !data.organization) return null

        const organizationResponse = data.organization

        // Set the organization timezone globally
        moment.tz.setDefault(organizationResponse.timezone)
        const organization = {
          slug: organizationSlug,
          ...organizationResponse,
        }
        return (
          <Context.Provider value={organization}>{children}</Context.Provider>
        )
      }}
    </Query>
  )
}

function withOrganization(Component) {
  return (props) => (
    <Context.Consumer>
      {(organization) => <Component organization={organization} {...props} />}
    </Context.Consumer>
  )
}

const useOrganization = () => useContext(Context)

export { Provider, withOrganization, useOrganization }
