import LifeLogo from "app/common/icons/LifeLogo"
import ReachLogo from "app/common/icons/ReachLogo"
import { useUniversalCtx } from "app/lib/universal-context"

export default function Logo(props) {
  const context = useUniversalCtx()

  if (!context) {
    return <LifeLogo {...props} />
  }

  const Logo = context.isReach ? ReachLogo : LifeLogo

  return <Logo {...props} />
}
