import { useEffect } from "react"
import useCreateAnalyticsEntry from "./useCreateAnalyticsEntry"

const fiveMinutes = 5 * 60000

function useAppUseMonitor() {
  const createAnalyticsEntry = useCreateAnalyticsEntry()

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (document.visibilityState === "visible") {
        createAnalyticsEntry("TIME_IN_APP")
      }
    }, fiveMinutes)

    return () => clearInterval(intervalId)
  }, [createAnalyticsEntry])
}

export default function TimeInAppMonitor() {
  useAppUseMonitor()

  return null
}
