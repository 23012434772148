import React from "react"
import CardImageDarkOverlay from "./CardImageDarkOverlay"
import CardCanceledLabel from "./CardCanceledLabel"

function CardCanceledOverlay(props) {
  return (
    <CardImageDarkOverlay {...props}>
      <CardCanceledLabel data-test-id="card-canceled-label" />
    </CardImageDarkOverlay>
  )
}

export default CardCanceledOverlay
