import React from "react"
import PageMessage from "app/common/PageMessage"
import Spinner from "app/common/Spinner"

export default function PageSpinner(props) {
  return (
    <PageMessage {...props}>
      <Spinner data-test-id="loading" role="alert" />
    </PageMessage>
  )
}
