import { useOrganization } from "app/common/Organization"
import { useLazyCreateSession } from "app/features/session/useCreateSession"
import { getFullAppVersion } from "app/utils"
import moment from "moment"
import { useEffect } from "react"
import { getProductDomain } from "../Product/ProductContext"

let useCreateSession = () => null

if (typeof window !== "undefined") {
  const TIMER_KEY = `wellzesta-web-session-tracker-key`
  const startTimer = () =>
    window.localStorage.setItem(TIMER_KEY, moment().toISOString())

  useCreateSession = () => {
    const [createSession] = useLazyCreateSession()
    const organization = useOrganization()

    const registerSession = async () => {
      let productDomain = window.location.hostname.split(".")[0]
      if (productDomain === "discover") {
        return ""
      }
      const stored = window.localStorage.getItem(TIMER_KEY)
      if (stored && organization && organization.id) {
        const from = moment(stored)
        const duration = moment.duration(moment().diff(from)).asSeconds()

        let appKind = `${getProductDomain(
          window.location.hostname
        ).productDomain.toUpperCase()}_WEB`

        // workaround until the AppKind enum is updated
        appKind = appKind === "ELEVATE_WEB" ? "ELEVATE" : appKind

        try {
          await createSession({
            variables: {
              appVersion: getFullAppVersion(),
              duration: Math.round(duration),
              organizationId: organization.id,
              appKind,
            },
          })
        } catch (err) {
          console.log("Error creating session", err)
        }
      }
    }

    useEffect(() => {
      startTimer()
      const sessionTracker = () => {
        if (window.document.visibilityState === "hidden") {
          registerSession()
        }

        if (window.document.visibilityState === "visible") {
          startTimer()
        }
      }

      document.addEventListener("visibilitychange", sessionTracker)

      return () =>
        document.removeEventListener("visibilitychange", sessionTracker)
    }, [window.document.visibilityState]) // eslint-disable-line react-hooks/exhaustive-deps

    return null
  }
}

export const UserSessionTracker = () => useCreateSession()
