import React, { useState, useEffect, useCallback } from "react"
import { useDialogState } from "reakit/Dialog"
import { gql, useQuery } from "@apollo/client"
import Router from "next/router"
import { useCurrentUser } from "app/lib/CurrentUser"
import EventFeedbackBody from "./EventFeedbackBody"
import EventQuestions from "./EventQuestions"
import { useOrganization } from "app/common/Organization"
import getConfig from "next/config"
import { useFeatureFlags } from "app/lib/hooks/useFeatureFlags"
import { WZ_FEEDBACK } from "app/lib/FeatureFlags/Constants"

const FeedbackContext = React.createContext()
const delayToShowFeedbackBanner = 2 * 60000 // 2 minutes

const unansweredEvents = gql`
  query GetUnansweredEvent {
    unansweredEvents(first: 1) {
      edges {
        node {
          id
          title
          startedAt
          endedAt
          feedbackRequestEnabled
          questions {
            edges {
              node {
                id
                questionType
                title
              }
            }
          }
        }
      }
    }
  }
`

export default function FeedbackProvider({ children }) {
  const { getFeatureFlag } = useFeatureFlags()
  const { publicRuntimeConfig } = getConfig()
  let wzEnableFeedbackCDN = getFeatureFlag(WZ_FEEDBACK)
  const md5 = require("md5")
  const currentUser = useCurrentUser()
  const organization = useOrganization()
  const [feedback, setFeedback] = useState(null)
  const dialog = useDialogState({ modal: false })
  const showFeedbackRequest = currentUser.hasPermission("event:register")
  const { data, startPolling, stopPolling } = useQuery(unansweredEvents, {
    skip: feedback || !showFeedbackRequest || !organization?.isChildless,
  })

  const getCDNFeedbackJson = async () => {
    const url = `https://${publicRuntimeConfig.NEW_CONTENT_BUCKET_NAME}/${md5(
      currentUser.id
    )}.json`
    console.debug(`CDN file url: ${url}`)
    const response = await fetch(url, {
      method: "GET",
      cache: "no-store",
    })

    console.debug(`CDN file response: ${response.status}`)
    if (response.status === 200) {
      const result = await response.json()
      console.log(`returning json ${result.unansweredFeedback}`)
      return result
    }
    console.log("returning json false")
    return { unansweredFeedback: false }
  }

  const closeFeedbackDialog = useCallback(() => {
    dialog.hide()
    setFeedback(null)
  }, [dialog])

  useEffect(async () => { // eslint-disable-line react-hooks/exhaustive-deps
    let interval = 2 * 60000 // 2 minutes
    console.log(`flag: ${wzEnableFeedbackCDN}`)
    if (wzEnableFeedbackCDN === "true") {
      try {
        const response = await getCDNFeedbackJson()
        console.log(`unansweredFeedback: ${response.unansweredFeedback}`)
        if (!response.unansweredFeedback) {
          interval = 30 * 60000 // 30 minutes
        }
      } catch (error) {
        console.error(`Error fetching CDN feedback JSON: ${error}`)
      }
    }

    if (showFeedbackRequest && organization?.isChildless) {
      console.log(`Start polling with interval ${interval}`)
      startPolling?.(interval)
    } else {
      console.log("Stop polling")
      stopPolling?.()
    }
    return () => stopPolling()
  }, [showFeedbackRequest, startPolling, stopPolling, organization?.isChildless,]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let delayTimer

    if (!feedback && data?.unansweredEvents?.edges?.length) {
      const [first] = data.unansweredEvents.edges.slice(0, 1)
      const { node: event } = first

      if (event) {
        delayTimer = setTimeout(() => {
          setFeedback({
            id: event.id,
            bodyElement: (
              <EventFeedbackBody
                title={"You were registered for this event. Did you attend?"}
                event={event}
                onCancel={closeFeedbackDialog}
                onConfirm={() => {
                  if (event.feedbackRequestEnabled) {
                    Router.push(
                      `/${organization.slug}/events/${event.id}/questions`
                    )
                  } else {
                    setFeedback(null)
                  }
                  dialog.hide()
                }}
              />
            ),
            renderQuestions: (props) => (
              <EventQuestions event={event} {...props} />
            ),
          })
          dialog.show()
        }, delayToShowFeedbackBanner)
      }
    }
    return () => clearTimeout(delayTimer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeFeedbackDialog,
    data?.unansweredEvents?.edges?.length,
    dialog,
    feedback,
  ])

  return (
    <FeedbackContext.Provider
      value={{
        dialogState: dialog,
        close: closeFeedbackDialog,
        feedback,
        clearFeedback: () => setFeedback(null),
      }}
    >
      {children}
    </FeedbackContext.Provider>
  )
}

export const useFeedbackState = () => React.useContext(FeedbackContext)
