const MOOD_ICONS = {
  TERRIBLE: TerribleIcon,
  NOT_GOOD: NotGoodIcon,
  AS_USUAL: AsUsualIcon,
  GOOD: GoodIcon,
  GREAT: GreatIcon,
}

export default function InputIcons({ mood, className }) {
  const Component = MOOD_ICONS[mood]
  return <Component className={className} />
}

function AsUsualIcon({ className }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M46.5 24C46.5 36.4262 36.4262 46.5 24 46.5C11.5738 46.5 1.5 36.4262 1.5 24C1.5 11.5738 11.5738 1.5 24 1.5C36.4262 1.5 46.5 11.5738 46.5 24Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        d="M12.1838 32.9146H34.7002"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7454 20.0146C17.7454 21.7421 16.3457 23.1419 14.6182 23.1419C12.8907 23.1419 11.4909 21.7421 11.4909 20.0146C11.4909 18.2871 12.8907 16.8873 14.6182 16.8873C16.3457 16.8873 17.7454 18.2871 17.7454 20.0146V20.0146Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5091 20.0146C36.5091 21.7421 35.1093 23.1419 33.3818 23.1419C31.6543 23.1419 30.2546 21.7421 30.2546 20.0146C30.2546 18.2871 31.6543 16.8873 33.3818 16.8873C35.1093 16.8873 36.5091 18.2871 36.5091 20.0146V20.0146Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
    </svg>
  )
}

function GoodIcon({ className }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M46.5 24C46.5 36.4262 36.4262 46.5 24 46.5C11.5738 46.5 1.5 36.4262 1.5 24C1.5 11.5738 11.5738 1.5 24 1.5C36.4262 1.5 46.5 11.5738 46.5 24Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        d="M12.7418 33.1492C12.7418 33.1492 15.8691 38.1528 24 38.1528C32.1309 38.1528 35.2582 33.1492 35.2582 33.1492"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7454 20.0146C17.7454 21.7421 16.3457 23.1419 14.6182 23.1419C12.8906 23.1419 11.4909 21.7421 11.4909 20.0146C11.4909 18.2871 12.8906 16.8873 14.6182 16.8873C16.3457 16.8873 17.7454 18.2871 17.7454 20.0146V20.0146Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5091 20.0146C36.5091 21.7421 35.1094 23.1419 33.3818 23.1419C31.6543 23.1419 30.2546 21.7421 30.2546 20.0146C30.2546 18.2871 31.6543 16.8873 33.3818 16.8873C35.1094 16.8873 36.5091 18.2871 36.5091 20.0146V20.0146Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
    </svg>
  )
}

function GreatIcon({ className }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M46.5 24C46.5 36.4262 36.4262 46.5 24 46.5C11.5738 46.5 1.5 36.4262 1.5 24C1.5 11.5738 11.5738 1.5 24 1.5C36.4262 1.5 46.5 11.5738 46.5 24Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7454 17.5127C17.7454 19.2403 16.3457 20.64 14.6182 20.64C12.8906 20.64 11.4909 19.2403 11.4909 17.5127C11.4909 15.7852 12.8906 14.3855 14.6182 14.3855C16.3457 14.3855 17.7454 15.7852 17.7454 17.5127V17.5127Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5091 17.5127C36.5091 19.2403 35.1094 20.64 33.3819 20.64C31.6543 20.64 30.2546 19.2403 30.2546 17.5127C30.2546 15.7852 31.6543 14.3855 33.3819 14.3855C35.1094 14.3855 36.5091 15.7852 36.5091 17.5127V17.5127Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2729 26.9031C11.3261 33.1577 17.0616 38.5754 24.0004 38.5754C30.9379 38.5754 36.6734 33.1064 37.7266 26.8518H10.2729V26.9031Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
    </svg>
  )
}

function NotGoodIcon({ className }) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M46.5 24C46.5 36.4262 36.4262 46.5 24 46.5C11.5738 46.5 1.5 36.4262 1.5 24C1.5 11.5738 11.5738 1.5 24 1.5C36.4262 1.5 46.5 11.5738 46.5 24Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7454 20.0146C17.7454 21.7421 16.3457 23.1419 14.6182 23.1419C12.8906 23.1419 11.4909 21.7421 11.4909 20.0146C11.4909 18.2871 12.8906 16.8873 14.6182 16.8873C16.3457 16.8873 17.7454 18.2871 17.7454 20.0146V20.0146Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.5091 20.0146C36.5091 21.7421 35.1094 23.1419 33.3819 23.1419C31.6543 23.1419 30.2546 21.7421 30.2546 20.0146C30.2546 18.2871 31.6543 16.8873 33.3819 16.8873C35.1094 16.8873 36.5091 18.2871 36.5091 20.0146V20.0146Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        d="M12.7418 35.651C12.7418 35.651 15.8691 30.6473 24 30.6473C32.1309 30.6473 35.2582 35.651 35.2582 35.651"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
    </svg>
  )
}

function TerribleIcon({ className }) {
  return (
    <svg
      width="50"
      height="48"
      viewBox="0 0 50 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M48.5 24C48.5 36.4262 38.4262 46.5 26 46.5C13.5738 46.5 3.5 36.4262 3.5 24C3.5 11.5738 13.5738 1.5 26 1.5C38.4262 1.5 48.5 11.5738 48.5 24Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7454 20.0146C19.7454 21.7421 18.3457 23.1419 16.6182 23.1419C14.8906 23.1419 13.4909 21.7421 13.4909 20.0146C13.4909 18.2871 14.8906 16.8873 16.6182 16.8873C18.3457 16.8873 19.7454 18.2871 19.7454 20.0146Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.5091 20.0146C38.5091 21.7421 37.1094 23.1419 35.3818 23.1419C33.6543 23.1419 32.2546 21.7421 32.2546 20.0146C32.2546 18.2871 33.6543 16.8873 35.3818 16.8873C37.1094 16.8873 38.5091 18.2871 38.5091 20.0146Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4001 36.8591C16.2145 31.8555 20.6427 27.8063 26.0004 27.8063C31.3568 27.8063 35.7863 31.8555 36.5994 36.8591H15.4001Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80191 25.2528C4.80191 25.2528 -1.45265 37.2928 4.80191 37.2928C12.3074 37.2928 4.80191 25.2528 4.80191 25.2528Z"
        fill="white"
        stroke="var(--color-bg-primary)"
        strokeWidth="3"
      />
    </svg>
  )
}
