import React from "react"
import CardImageOverlay from "./CardImageOverlay"

export default function CardImageDarkOverlay(props) {
  return (
    <CardImageOverlay
      style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}
      {...props}
    />
  )
}
