export default {
  palette: {
    primary: ["#092B2B", "#214040", "#476060"],
    secondary: ["#EEF1EF", "#788B81", "#4A6457"],
    secondaryText: ["#476060", "#fff", "#fff"],
    tertiary: ["#166E3B", "#24A159", "#2CC467"],
    tertiaryText: ["#fff", "#fff", "#fff"],
    danger: ["#DE4D48", "#F85651", "#F97773"],
    black: "#2C2C2C",
    black10: "#e5e5e5",
    text: "#2C2C2C",
    text75: "#616161",
    text50: "#959595",
    text25: "#CACACA",
    neutral: "#F4F5EF",
    lightGreen: "#B8E986",
  },
  spacing: {
    pageWidth: "1200px",
    small: "0.8rem",
    normal: "1.6rem",
    large: "2.4rem",
  },
  font: {
    inter: "Inter",
    crimson: "Crimson Text",
  },
  fontWeight: {
    regular: 400,
    semibold: 600,
    bold: 700,
  },
  zIndex: {
    select: 100,
    header: 200,
  },
  breakpoints: {
    small: 500,
    medium: 768,
    large: 1024,
  },
}
