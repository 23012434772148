import React from "react"
import { Dialog } from "reakit/Dialog"
import { Button } from "app/wellzesta-ui"
import CloseIcon from "app/common/icons/Close"
import { useFeedbackState } from "./FeedbackContext"
import { useMobile } from "app/lib/hooks/useBreakpoints"
import clsx from "clsx"

export default function FeedbackDialog() {
  const { dialogState, close, feedback } = useFeedbackState()
  const isMobile = useMobile()

  return (
    <Dialog
      {...dialogState}
      aria-label="Feedback"
      className="absolute py-2 w-full bg-white z-main shadow"
      hideOnClickOutside={false}
      tabIndex={0}
    >
      <div className="py-4 px-4 mx-auto md:max-w-screen-xl flex flex-wrap">
        <div className="inline-block w-10/12">{feedback?.bodyElement}</div>
        <div className="inline-block w-1/6 flex items-start">
          <Button
            theme="inverse"
            size={isMobile ? "none" : "sm"}
            onClick={close}
            aria-label="close"
            className={clsx("ml-4 order-1 ml-auto", {
              "rounded-full px-3": isMobile,
            })}
          >
            {isMobile ? <CloseIcon className="w-5 h-5" /> : "Dismiss"}
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
