import { merge } from "lodash"
import React from "react"
import ReactSelect from "react-select"
import theme from "../theme" // TODO: get theme from styled-components context

const styles = {
  container: (styles) => ({
    ...styles,
    width: "100%",
    cursor: "pointer",
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: theme.zIndex.select,
  }),
  placeholder: (styles) => ({ ...styles, color: "var(--black)" }),
  singleValue: (styles) => ({
    ...styles,
    color: "var(--black)",
  }),
  control: () => ({
    backgroundColor: "var(--color-bg-inverse-darken)",
    width: "100%",
    minWidth: 240,
    minHeight: 44,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    borderRadius: "4px",
  }),
  option: (base, state) => ({
    ...base,
    alignItems: "center",
    display: "flex",
    color: state.isSelected ? "var(--white)" : "var(--black)",

    ":active": {
      color: "var(--white)",
      backgroundColor:
        !state.isDisabled &&
        (state.isSelected
          ? state.theme.colors.primary
          : state.theme.colors.primary50),
    },
  }),
}

const getTheme = (baseTheme) => ({
  ...baseTheme,
  colors: {
    ...baseTheme.colors,
    primary: "var(--color-bg-primary)",
    primary25: "var(--gray-100)",
    primary50: "var(--color-bg-primary-darken)",
    neutral20: "var(--gray-500)",
    neutral40: "var(--gray-600)",
    neutral60: "var(--gray-700)",
    neutral70: "var(--gray-800)",
    neutral80: "var(--gray-450)",
  },
})

export default function Select({ styles: customStyles, selectRef, ...props }) {
  return (
    <ReactSelect
      ref={selectRef}
      classNamePrefix="react-select"
      styles={merge(styles, customStyles)}
      theme={getTheme}
      {...props}
    />
  )
}
