import React from "react"
import PropTypes from "prop-types"
import { getDataFromTree } from "@apollo/client/react/ssr"
import initApollo from "./initApollo"
import { createUniversalContext } from "./universal-context"

const withApollo = (App) => {
  return class WithData extends React.Component {
    static displayName = `WithData(${App.displayName})`
    static propTypes = {
      apolloState: PropTypes.object.isRequired,
    }

    static async getInitialProps(nextAppCtx) {
      const {
        Component,
        router,
        ctx: { res },
      } = nextAppCtx

      const apollo = initApollo(
        {},
        {
          ...createUniversalContext(nextAppCtx.ctx),
          ctx: nextAppCtx.ctx,
        }
      )

      nextAppCtx.ctx.apolloClient = apollo

      let appProps = {}
      if (App.getInitialProps) {
        appProps = await App.getInitialProps(nextAppCtx)
      }

      if (res && res.finished) {
        // When redirecting, the response is finished.
        // No point in continuing to render
        return {}
      }

      if (typeof window === "undefined") {
        // Run all graphql queries in the component tree
        // and extract the resulting data
        try {
          // Run all GraphQL queries
          // TODO: in order to fix the errors caused by useRouter() returning undefined it is needed to use
          // AppTree component provided by nextAppCtx instead of App. But using this causes the apollo cache
          // to dissapear. Check if in the future this is working fine
          await getDataFromTree(
            <App
              {...appProps}
              nextCtx={nextAppCtx.ctx}
              Component={Component}
              router={router}
              apolloClient={apollo}
            />
          )
        } catch (error) {
          // Prevent Apollo Client GraphQL errors from crashing SSR.
          // Handle them in components via the data.error prop:
          // https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-query-data-error
          error.message =
            "Error while running `getDataFromTree`\n" + error.message
          // No need to use reportError because this will likely be reported on _app. Avoiding spamming rollbar with errors.
          console.log(error)
        }
      }

      // Extract query data from the Apollo's store
      const apolloState = apollo.cache.extract()

      return {
        ...appProps,
        apolloState,
      }
    }

    constructor(props) {
      super(props)

      // `getDataFromTree` renders the component first, the client is passed off as a property.
      // After that rendering is done using Next's normal rendering pipeline
      this.apolloClient = initApollo(props.apolloState, {
        ...createUniversalContext(props.nextCtx),
        ctx: props.nextCtx,
      })
    }

    render() {
      return <App {...this.props} apolloClient={this.apolloClient} />
    }
  }
}

export default withApollo
