const useLocalStorage = () => {
  const setItem = (key, value) => {
    setItemWithExpiry(key, value, 60*60*24*365)
  }

  const setItemWithExpiry = (key, value, expiryInSeconds) => {
    try {
      const now = new Date()

      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + (expiryInSeconds * 1000),
      }
      window.localStorage.setItem(key, JSON.stringify(item))
    } catch (err) {
      console.error("setItemWithExpiry: ", err)
    }
  }

  const getItem = (key) => {
    try {
      const itemStr = window.localStorage.getItem(key)

      // if the item doesn't exist, return null
      if (!itemStr) {
        return null
      }

      const item = JSON.parse(itemStr)
      const now = new Date()
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        window.localStorage.removeItem(key)
        return null
      }
      return item.value
    } catch (err) {
      console.error("getWithExpiry: ", err)
      return null
    }
  }

  const removeItem = (key) => {
    try {
      window.localStorage.removeItem(key)
    } catch (err) {
      console.error("deleteItem: ", err)
      return null
    }
  }

  return {
    setItem,
    setItemWithExpiry,
    getItem,
    removeItem,
  }
}

export default useLocalStorage
