import { useContext } from "react"
import { FeatureFlagsContext } from "app/lib/FeatureFlags/FeatureFlagsProvider"

export const useFeatureFlags = () => {
  const { featureFlags } = useContext(FeatureFlagsContext)
  const getFeatureFlag = (key) => {
    if (featureFlags && featureFlags[key]) {
      return featureFlags[key]
    }
    return ""
  }

  const isFeatureFlagEnabledForOrg = (key, organizationId) => {
    const flagValue = getFeatureFlag(key)

    try {
      const flagOrgs = flagValue ? flagValue.split(",") : []
      return (organizationId && flagOrgs.includes(organizationId)) || flagOrgs.includes("all")
    } catch (e) {
      return false
    }

  }

  return {
    featureFlags,
    getFeatureFlag,
    isFeatureFlagEnabledForOrg,
  }
}
