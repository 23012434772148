import React from "react"
import { useFormikContext } from "formik"
import { Button, Text } from "app/wellzesta-ui"
import InputIcons from "./InputIcons"

const MOOD_ITEMS = [
  { label: "Great", value: "GREAT" },
  { label: "Good", value: "GOOD" },
  { label: "As usual", value: "AS_USUAL" },
  { label: "Not good", value: "NOT_GOOD" },
  { label: "Terrible", value: "TERRIBLE" },
]

export default function MoodInput({ name, value, onChange }) {
  const { submitForm } = useFormikContext()

  return (
    <ul className="flex flex-col space-y-4">
      {MOOD_ITEMS.map((it) => {
        const active = value === it.value

        const textStyle = "ml-4 block " + (active ? "text-white" : "text-black")

        return (
          <li className="flex flex-col " key={it.value}>
            <Button
              name={name}
              size="sm"
              theme="mood"
              className="py-2 flex items-center"
              active={active}
              value={it.value}
              onClick={(e) => {
                e.persist()
                onChange(it.value)
                setTimeout(submitForm, 0)
              }}
              aria-label={`rate your mood ${it.label}`}
            >
              <InputIcons mood={it.value} className="w-10 h-10" />
              <Text size="sm" className={textStyle}>
                {it.label}
              </Text>
            </Button>
          </li>
        )
      })}
    </ul>
  )
}
