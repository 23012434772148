import React from "react"
import { Box, Heading } from "app/wellzesta-ui"
import { useOrganization } from "app/common/Organization"
import CurrentUserMenu from "../CurrentUserMenu"
import OrgLogo from "app/common/icons/OrgLogo"
import { useRouter } from "next/router"
import {useProduct} from 'app/lib/Product/ProductContext'

export function HeaderBar({ children }) {
  return (
    <header className="z-20 bg-inverse-lighten text-black shadow">
      <div
        data-test-id="HeaderRow"
        className="py-3 px-3 md:px-4 mx-auto flex justify-between"
      >
        {children}
      </div>
    </header>
  )
}

function Header({ openSidebarButton, children }) {
  const organization = useOrganization()
  const { isDiscover } = useProduct()
  const router = useRouter()

  return (
    <div className="top-0 sticky z-main primary-header">
      <HeaderBar>
        <div className="flex items-center min-w-0">
          {openSidebarButton}
          <Box
            className="flex flex-row cursor-pointer"
            onClick={() => {
              if (!isDiscover) {
                router.push(`/${router.query?.organizationSlug}/home`)
              }
            }}
          >
            <Box className="mr-2 md:mr-3 flex-grow-0 flex-shrink-0 min-w-0">
              <OrgLogo
                className="w-8 h-8 rounded-md"
                logoUrl={
                  !organization?.logoUrl
                    ? "/static/images/avafield.png"
                    : organization.logoUrl
                }
              />
            </Box>
            <Heading size="sm" as="div" className="font-bold truncate">
              {organization?.shortName}
            </Heading>
          </Box>
        </div>

        <div className="flex items-center min-w-0 space-x-6">
          <CurrentUserMenu />
        </div>
      </HeaderBar>
      {children}
    </div>
  )
}

export default Header
