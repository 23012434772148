import { Button, Text } from "app/wellzesta-ui"
import clsx from "clsx"
import { useNotification } from "app/features/notification/NotificationContext"

export const NotificationBody = ({
  title,
  isAlert,
  notification,
  onViewDetail,
}) => {
  const { close } = useNotification()

  return (
    <>
      <div>
        <Text
          size="md"
          className={clsx("block font-bold", {
            "text-red-700": isAlert,
          })}
        >
          {title}
        </Text>
        <Text
          size="sm"
          className={clsx("block", {
            "text-red-700": isAlert,
          })}
        >
          {notification.body}
        </Text>
      </div>

      <div className="pt-6 flex space-x-3 ml-auto w-full order-2">
        <Button
          theme="dark"
          size={"sm"}
          onClick={close}
          aria-label="close"
          className=""
        >
          Dismiss
        </Button>
        <Button theme="primary" size="sm" onClick={() => onViewDetail()}>
          View details
        </Button>
      </div>
    </>
  )
}
