export const getGATrackingIDByProduct = (productName) => {
  return {
    reach: "G-CQT2V7ST9V",
    life: "G-XQ0TLTLPRX",
    connect: "G-31TSD4FDT2",
    elevate: "G-VKFYXY6HCV",
    discover: "G-WZK8LZSK5Y",
  }[productName]
}

export const pageView = (url, gaTrackingID) => {
  window.gtag("config", gaTrackingID, {
    page_path: url,
  })
}

export const customEvent = ({
  name,
  label,
  category,
  action = "click",
  data = {},
}) => {
  if (typeof window === "undefined") return
  if (!window.gtag) return
  if (process.env.NODE_ENV !== "production") return
  const newData = {
    date: new Intl.DateTimeFormat("en-US", {
      dateStyle: "full",
      timeStyle: "long",
      timeZone: "America/New_York",
    }).format(new Date()),
    ...data,
  }
  window.gtag("event", name, {
    event_category: category,
    event_action: action,
    event_label: label,
    ...newData,
  })
}
