import { useUniversalCtx } from "../universal-context"
import Router from "next/router"
import { removeItem as customStorageRemoveItem } from "app/lib/customStorage"

const TOKEN = "token"

const browserOnly =
  (func) =>
  (...args) => {
    if (typeof window === "undefined") {
      throw new Error("can only be executed in a browser environment")
    }
    return func(...args)
  }

export class AuthService {
  constructor({ url, cookies } = {}) {
    this.url = url
    this.cookies = cookies
  }

  login = browserOnly(() => {
    Router.replace("/login")
  })

  saveLastAccessedPath = browserOnly(() => {
    window.localStorage.setItem(
      "last_accessed_path",
      this.url.pathname + this.url.search
    )
  })

  setLastAccessedPath = browserOnly((path) => {
    window.localStorage.setItem("last_accessed_path", path)
  })

  getLastAccessedPath = browserOnly(() => {
    return window.localStorage.getItem("last_accessed_path")
  })

  popLastAccessedPath = browserOnly(() => {
    const url = window.localStorage.getItem("last_accessed_path")
    window.localStorage.removeItem("last_accessed_path")
    return url
  })

  // Had to be duplicated on the server_side_session_cookie endpoint, unfortunately
  getCookieDomain() {
    const wellzestaStagingHost = ".stg.wellzesta.com"
    const wellzestaHost = ".wellzesta.com"
    const wellzestaDevHost = ".wellzesta.local"
    const hostname = this.url.hostname

    if (hostname.endsWith(wellzestaStagingHost)) {
      return wellzestaStagingHost
    }
    if (hostname.endsWith(wellzestaHost)) {
      return wellzestaHost
    }
    if (hostname.endsWith(wellzestaDevHost)) {
      return wellzestaDevHost
    }
    return hostname
  }

  logout() {
    this.popLastAccessedPath()
    customStorageRemoveItem("FEATURE_FLAGS")
    // If the server receives the wrong token it will return a NotAuthorized which the client handles and call this method
    // We remove all possible tokens in order to prevent the site to use the wrong token for the wrong env and dont get stuck in the login
    ;[".wellzesta.com", ".stg.wellzesta.com", this.getCookieDomain()].forEach(
      (domain) => {
        this.cookies.remove(TOKEN, {
          domain,
          path: "/",
        })
      }
    )
  }

  isAuthenticated() {
    return Boolean(this.getToken())
  }

  getToken() {
    return this.cookies.get(TOKEN)
  }

  async setToken(token) {
    this.cookies.set(TOKEN, token, {
      domain: this.getCookieDomain(),
      path: "/",
      maxAge: 365 * 24 * 60 * 60,
      secure: false,
    })

    await this.serverSideCookieRefresh()

    this.iOSSaveSessionToken(token)
  }

  async serverSideCookieRefresh() {
    return window.fetch("/api/server_side_session_cookie", {
      method: "POST",
      body: {},
    })
  }

  iOSSaveSessionToken(token) {
    if (window.iOSPushCapability) {
      console.log("iOSSaveSessionToken ")
      window.webkit.messageHandlers["save-session-token"].postMessage(token)
    }
  }
}

const useAuth = () => new AuthService(useUniversalCtx())

export default useAuth
