import React from "react"

export default function CardCanceledLabel() {
  return (
    <div className="flex justify-center items-center flex-grow h-full w-full">
      <div className="text-red-200 border-2 border-current text-xl font-bold uppercase min-h-action px-8 flex items-center rounded">
        Canceled
      </div>
    </div>
  )
}
