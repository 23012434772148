import React from "react"
import { Checkbox, Group, Button } from "app/wellzesta-ui"

export default function ToggleInput({ name, value, onChange }) {
  return (
    <Group className="flex items-center">
      <Button
        as="label"
        theme="inverse"
        className="flex rounded-r-none uppercase items-center"
        active={!value}
      >
        <Checkbox
          name={`${name}-no-option`}
          checked={!value}
          onChange={() => onChange(false)}
          size="md"
          className="hidden"
        />
        No
      </Button>
      <Button
        as="label"
        theme="inverse"
        className="flex rounded-l-none uppercase items-center"
        active={value}
      >
        <Checkbox
          name={`${name}-yes-option`}
          checked={value}
          onChange={() => onChange(true)}
          size="md"
          className="hidden"
        />
        Yes
      </Button>
    </Group>
  )
}
