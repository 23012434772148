import React from "react"
import Router from "next/router"
import MainColumn from "app/common/MainColumn"
import { Heading, Text, Button } from "app/wellzesta-ui"

const ServerErrorPage = (props) => {
  const { organizationSlug, clearError } = props

  const handleOnClick = () => {
    clearError()

    if (organizationSlug !== "" && organizationSlug !== "undefined") {
      Router.push("/[organizationSlug]/home", `/${organizationSlug}/home`)
    } else {
      Router.push("/")
    }
  }

  return (
    <MainColumn size="md">
      <div className="flex justify-center flex-col items-center mt-40 text-center">
        <Heading size="md" as="h2" className="font-bold">
          Error
        </Heading>
        <Text size="md" as="h3" marginTop="2rem">
          An unexpected error has occurred.
        </Text>
        <Button theme="primary" className="mt-6" onClick={handleOnClick}>
          Retry
        </Button>
      </div>
    </MainColumn>
  )
}

ServerErrorPage.withNoLayout = false
export default ServerErrorPage
