import React, { useContext } from "react"
import Cookies from "universal-cookie"
import isMobile from "is-mobile"
import { getProductDomain } from "app/lib/Product/ProductContext"

const Context = React.createContext()

const getProtocol = (req) => {
  let proto = req.connection.encrypted ? "https" : "http"
  // only do this if you trust the proxy
  proto = req.headers["x-forwarded-proto"] || proto
  return proto.split(/\s*,\s*/)[0]
}

const getAbsoluteUrl = (req) =>
  `${getProtocol(req)}://${req.headers.host}${req.url}`

export const createUniversalContext = (nextCtx) => {
  if (typeof window !== "undefined") {
    // client side
    return {
      url: new URL(window.location.href),
      cookies: new Cookies(),
      isMobile: isMobile(),
      redirect: (url) => (window.location.href = url),
      ...getProductDomain(window.location.hostname),
    }
  } else {
    // server side
    return {
      url: new URL(getAbsoluteUrl(nextCtx.req)),
      cookies: new Cookies(nextCtx.req.headers.cookie),
      isMobile: isMobile({ ua: nextCtx.req.headers["user-agent"] }),
      redirect: (url) => {
        nextCtx.res.writeHead(302, { Location: url })
        nextCtx.res.end()
      },
      ...getProductDomain(nextCtx.req.headers.host),
    }
  }
}

/**
 * This hook provides access to an unified API that can be used in both client and server side.
 * It avoids the if client do this if server do that everywhere.
 *
 * How it works: in the client side we create the API using the browser globals, easy peasy.
 * On the server side we pass the next context via context API, which holds node's request and response objects.
 * We use those objects to create the server side version. The context API is used so we can access those API's
 * in the react's render methods, otherwise request and response would only be available in the getInitialProps
 * methods.
 * One gotcha about passing the next context on SSR is that we wrap it in a proxy object which overrides the toJSON
 * method to avoid cyclic references errors.
 */

export const useUniversalCtx = () => {
  const nextCtx = useContext(Context)
  return createUniversalContext(nextCtx)
}

export const Provider = ({ value, children }) => (
  <Context.Provider value={value}>{children}</Context.Provider>
)
