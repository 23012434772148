import React from "react"
import { gql, useMutation } from "@apollo/client"
import { Text, Button } from "app/wellzesta-ui"
import DatePrinter from "app/common/DatePrinter"
import { useMobile } from "app/lib/hooks/useBreakpoints"
import clsx from "clsx"

const confirmEventAttendanceMutation = gql`
  mutation ConfirmEventAttendance($eventId: ID!, $attended: Boolean!) {
    confirmEventAttendance(eventId: $eventId, attended: $attended) {
      id
    }
  }
`

export default function EventFeedbackBody({
  title,
  event,
  onCancel,
  onConfirm,
}) {
  const [confirmEventAttendance, { loading, error }] = useMutation(
    confirmEventAttendanceMutation
  )
  const isMobile = useMobile()

  return (
    <>
      <div>
        <Text size="md" className="block font-bold">
          {title}
        </Text>
        <Text size="sm" className="block">
          {event.title + " "}
          <DatePrinter value={event.startedAt} format="MMM DD, h:mm A" />
        </Text>
      </div>

      {error && (
        <Text role="alert" size="sm" className="block my-2 text-red-200">
          Something happened! Please, try again.
        </Text>
      )}

      <div className="pt-6 flex space-x-3 ml-auto w-full order-2">
        <Button
          theme="dark"
          size="sm"
          disabled={loading}
          onClick={() =>
            confirmEventAttendance({
              variables: { eventId: event.id, attended: false },
            }).then(onCancel)
          }
          className={clsx({ "w-full": isMobile })}
        >
          No
        </Button>
        <Button
          theme="primary"
          size="sm"
          disabled={loading}
          onClick={() =>
            confirmEventAttendance({
              variables: { eventId: event.id, attended: true },
            }).then(onConfirm)
          }
          className={clsx({ "w-full": isMobile })}
        >
          Yes
        </Button>
      </div>
    </>
  )
}
