import TerribleFigure from "app/features/mood-checkin/form/feedback/TerribleFigure"
import NotGoodFigure from "app/features/mood-checkin/form/feedback/NotGoodFigure"
import AsUsualFigure from "app/features/mood-checkin/form/feedback/AsUsualFigure"
import GoodFigure from "app/features/mood-checkin/form/feedback/GoodFigure"
import GreatFigure from "app/features/mood-checkin/form/feedback/GreatFigure"

const MOOD_FIGURES = {
  TERRIBLE: TerribleFigure,
  NOT_GOOD: NotGoodFigure,
  AS_USUAL: AsUsualFigure,
  GOOD: GoodFigure,
  GREAT: GreatFigure,
}

export default function FeedbackFigure({ mood }) {
  const Component = MOOD_FIGURES[mood]
  return <Component />
}
