import clsx from "clsx"
import React from "react"

function Input({ className, disabled, settings = "bg-gray-300", ...props }) {
  return (
    <input
      className={clsx(
        `px-2 w-full rounded h-11 ${settings} font-bold border-2 border-transparent focus:border-brand-blue-light placeholder-gray-600`,
        className,
        disabled && "cursor-not-allowed"
      )}
      disabled={disabled}
      {...props}
    />
  )
}

export function Textarea({
  name,
  value,
  onChange,
  rows = "3",
  cols = "50",
  placeholder,
}) {
  return (
    <textarea
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      rows={rows}
      cols={cols}
      className={clsx(
        "px-2 pt-1 block w-full bg-gray-300 rounded font-semibold border-2 border-transparent focus:border-brand-blue-light placeholder-gray-600"
      )}
      style={{ resize: "none" }}
    />
  )
}

export default Input
