import React from "react"
import { useAuth } from "./index"

const privatePage = (Wrapper) => {
  function PrivatePage(props) {
    const auth = useAuth()

    if (auth.getToken()) {
      return <Wrapper {...props} />
    }

    if (typeof window !== "undefined") {
      auth.saveLastAccessedPath()
      auth.login()
    }

    return null
  }

  PrivatePage.getInitialProps = async (props) => {
    if (typeof Wrapper.getInitialProps === "function") {
      await Wrapper.getInitialProps(props)
    }
  }

  return PrivatePage
}

export default privatePage
