import { gql, useMutation } from "@apollo/client"

const CREATE_SESSION_MUTATION = gql`
  mutation CreateSession(
    $appKind: AppKind
    $appVersion: String
    $duration: Int!
    $organizationId: ID!
  ) {
    createSessionTime(
      appKind: $appKind
      appVersion: $appVersion
      duration: $duration
      organizationId: $organizationId
    ) {
      id
    }
  }
`

export const useLazyCreateSession = () => useMutation(CREATE_SESSION_MUTATION)
