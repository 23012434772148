import clsx from "clsx"
import React from "react"
import { Box } from "reakit"

const Link = React.forwardRef(
  ({ theme, size, children, className, hidden, disabled, ...props }, ref) => {
    const paddingStyles = {
      xs: "is-xs",
      sm: "is-sm",
      md: "is-md",
      lg: "is-lg",
    }[size]

    const fontWeight = React.useMemo(
      () => className?.match(/font-(normal|semibold)/) ?? "font-semibold",
      [className]
    )

    const themeStyles = {
      primary:
        "text-primary focus:text-primary-darken active:text-primary-darken hover:text-primary-lighten",
      secondary:
        "text-secondary focus:text-secondary-darken active:text-secondary-darken hover:text-secondary-lighten",
      tertiary:
        "text-tertiary focus:text-tertiary-darken active:text-tertiary-darken hover:text-tertiary-lighten",
      inverse:
        "text-inverse focus:text-inverse-darken active:text-inverse-darken hover:text-inverse-lighten",
      accent:
        "text-accent focus:text-accent-darken active:text-accent-darken hover:text-accent-lighten",
    }[theme]

    return (
      <Box
        as="a"
        ref={ref}
        className={clsx(
          `action focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:ring-blue-400 
          flex items-center min-h-action`,
          paddingStyles,
          themeStyles,
          className,
          fontWeight,
          hidden && "invisible",
          disabled && "cursor-not-allowed"
        )}
        hidden={hidden}
        disabled={disabled}
        {...props}
      >
        {children}
      </Box>
    )
  }
)

export default Link
