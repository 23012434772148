import React from "react"

// this is used to wrap the next context so that when it is serialized by the server rendering
// we dont have cyclic errors
const preventJsonSerialization = (obj) => {
  const proxy = Object.create(obj)
  proxy.toJSON = () => "null"
  return proxy
}

const withUniversalContext = (App) => {
  function WithUniversalContext({ nextCtx, ...props }) {
    return <App {...props} nextCtx={nextCtx} />
  }

  WithUniversalContext.getInitialProps = async (nextAppCtx) => {
    let appProps = {}
    const { ctx } = nextAppCtx

    ctx.nextCtx = preventJsonSerialization(ctx)

    if (App.getInitialProps) {
      appProps = await App.getInitialProps(nextAppCtx)
    }

    appProps.nextCtx = preventJsonSerialization(ctx)

    return appProps
  }

  return WithUniversalContext
}

export default withUniversalContext
