export default function ReachLogo({ className }) {
  return (
    <svg
      width="40"
      height="40"
      className={className}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.1383 28.5068C25.7803 30.0084 20.4184 32.3302 18.0322 33.3034C16.4882 31.7046 13.5091 27.3806 11.151 24.878C8.20335 21.7498 3.08362 19.1222 2.45199 14.117C1.82036 9.11184 4.44772 6.69741 5.82069 7.23491C7.95155 8.0691 11.9265 20.1649 13.4003 20.9991C14.8741 21.8333 16.1073 20.2246 14.6635 14.7426C13.4003 9.94603 12.3476 8.27765 12.5581 5.14943C12.7586 2.17008 17.7247 -0.529034 18.7496 2.00897C20.0129 5.13719 17.1499 17.8709 19.506 19.1222C21.8622 20.3734 22.4537 12.0315 23.5064 8.0691C24.5591 4.10668 25.6118 1.4151 27.2962 1.20655C28.9805 0.998 30.8754 4.31523 30.6649 6.19217C30.2438 9.94603 23.5064 15.3683 24.7696 17.8709C25.3651 19.0506 27.5067 15.9939 29.8227 13.4914C32.1387 10.9888 36.139 7.86055 37.6128 9.32039C39.0866 10.7802 38.2625 13.9818 35.9465 15.6501C34.1364 16.9541 29.1047 17.8709 27.2962 19.6623C25.9465 20.9991 25.3167 23.2931 25.9483 25.3786C26.7905 24.5444 28.9805 20.9135 30.6649 19.6623C32.4554 18.3321 33.823 18.411 35.0863 19.6623C36.6966 21.2573 31.9281 26.0936 28.1383 28.5068Z"
        fill="white"
      />
      <path
        d="M10.0596 32.0656C8.8396 31.9456 7.54154 32.9107 7.15408 34.1585C6.76661 35.4064 8.02015 36.6091 9.93326 36.7594C11.8359 36.9435 15.8637 34.5704 15.2696 33.3875C14.6313 32.2279 11.29 32.1518 10.0596 32.0656Z"
        fill="#0071BB"
      />
      <path
        d="M5.71122 23.5119C3.05671 23.1617 1.0438 24.3253 1.1692 26.1186C1.29459 27.9118 2.77707 29.6102 4.40021 29.8377C6.07182 30.0617 10.5738 31.0619 11.7909 29.6618C12.963 28.3135 8.32065 23.9138 5.71122 23.5119Z"
        fill="#A46AAC"
      />
      <path
        d="M29.9497 30.8326C31.3898 30.4832 33.0982 31.4192 33.7456 32.8305C34.4031 34.2829 33.1291 35.8555 30.8662 36.4046C28.5933 36.9126 23.4532 34.7617 23.9666 33.2865C24.5623 31.7914 28.5409 31.1309 29.9497 30.8326Z"
        fill="#EC1D24"
      />
      <path
        d="M33.5554 25.947C34.217 25.4833 35.2746 25.6173 35.9009 26.2083C36.5272 26.7993 36.1968 27.8956 35.147 28.601C34.1142 29.3225 31.0336 29.2636 30.9919 28.3922C30.9831 27.5198 32.8769 26.3947 33.5554 25.947Z"
        fill="#F6931D"
      />
    </svg>
  )
}
