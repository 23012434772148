import React, { useMemo } from "react"
import { unsubscribeFromTopics } from "app/lib/firebase"
import { get } from "lodash/fp"
import { useCurrentUser, userTypes } from "app/lib/CurrentUser"
import { useAuth } from "app/lib/auth"
import UserAvatar from "app/common/UserAvatar"
import Router from "next/router"
import { useOrganization } from "app/common/Organization"
import Guest from "app/common/icons/Guest"
import InternalLink from "app/common/InternalLink"
import { useMenuState, Menu, MenuItem, MenuButton } from "reakit/Menu"
import { Button } from "app/wellzesta-ui"
import clsx from "clsx"
import { useProduct } from "app/lib/Product/ProductContext"
import moment from "moment"
import useUserMonthlyScoreQuery from "app/features/my-progress/useUserMonthlyScoreQuery"
import { useUniversalCtx } from "app/lib/universal-context"

const userMaker = {
  makeGuest: () => ({
    Name: () => "Guest",
    Avatar: ({ className, ...props }) => (
      <Guest className={`w-8 h-8 ${className}`} {...props} />
    ),
  }),
  makeCommunity: (organization) => ({
    Name: () => organization?.name,
    Avatar: ({ className, ...props }) => (
      <Guest className={`w-8 h-8 ${className}`} {...props} />
    ),
  }),
  makeDefault: (currentUser) => ({
    Name: () => currentUser.fullName,
    Email: () => currentUser.email,
    Avatar: ({ className, ...props }) => (
      <UserAvatar className={`border-2 border-white ${className}`} {...props} />
    ),
  }),
}

function ValidateUser({ organization, menu }) {
  const startedFrom = moment().startOf("month").format("YYYY-MM-DD")
  const startedUntil = moment().endOf("month").format("YYYY-MM-DD")

  const userProgress = useUserMonthlyScoreQuery({ startedFrom, startedUntil })
  const { isElevate } = useUniversalCtx()

  const points = userProgress
    ? userProgress?.monthlyScore?.[0]?.pointsAwarded
    : 0

  if (isElevate) {
    return <></>
  }
  return (
    <div className={"bg-inverse rounded-md pl-2 pt-4"}>
      <p className={"font-semibold"}>Points this month</p>
      <p className={"font-extrabold text-4xl"}>{points}</p>
      <hr className="border-inverse-darken" orientation="horizontal" />
      <button
        /* eslint-disable-next-line react/no-unknown-property */
        theme="transparent"
        className="underline text-left p-2 pl-0 w-full text-primary font-semibold hover:text-primary-darken"
        onClick={() => {
          menu.setVisible(false)
          Router.push(`/${organization.slug}/progress`)
        }}
      >
        View details
      </button>
    </div>
  )
}

export default function CurrentUserMenu() {
  const menu = useMenuState()
  const currentUser = useCurrentUser()
  const organization = useOrganization()
  const auth = useAuth()
  const { isReach, isConnect, isDiscover } = useProduct()
  const userType = get("userType", currentUser)

  const user = useMemo(() => {
    if (userType === userTypes.GUEST) {
      return userMaker.makeGuest()
    } else if (userType === userTypes.COMMUNITY) {
      return userMaker.makeCommunity(organization)
    } else {
      return userMaker.makeDefault(currentUser)
    }
  }, [currentUser, organization, userType])

  if (userType === userTypes.COMMUNITY || isDiscover) {
    return <></>
  }

  const handleSignOut = () => {
    auth.logout()
    return Promise.all([
      Router.push(`/login`),
      unsubscribeFromTopics(currentUser, organization),
    ])
  }

  const currentOrgSlug = organization?.slug || currentUser.organization.slug
  return (
    <div className={"mt-1 flex-shrink-0"} data-test-id="open-user-menu">
      <MenuButton
        as={Button}
        theme="is-inverse"
        size="xs"
        className="justify-center items-center"
        {...menu}
      >
        <user.Avatar user={currentUser} className="w-12 h-12" />
      </MenuButton>
      <Menu
        {...menu}
        className="w-65 md:w-70 rounded overflow-hidden shadow z-50 -ml-3 mt-1"
        aria-label="User menu"
        tabIndex={0}
      >
        <div className="flex flex-col bg-white text-black ">
          <div className="flex items-center px-4 py-3 w-full">
            <user.Avatar
              user={currentUser}
              className="w-24 h-24 flex-shrink-0"
            />

            <div className={"flex flex-col pl-3 pr-3 overflow-hidden"}>
              <p className="font-semibold leading-normal text-lg md:leading-custom md:text-md  truncate">
                {user.Name()}
              </p>
              {user.Email ? (
                <p className="text-sm leading-normal md:text-sm md:leading-custom font-normal truncate">
                  {user.Email()}
                </p>
              ) : null}
            </div>
          </div>
          <hr className="border-inverse-darken" orientation="horizontal" />
          <div className="flex flex-col space-y-4 p-4">
            {userType !== userTypes.GUEST ? (
              <ValidateUser organization={organization} menu={menu} />
            ) : null}
            {userType === userTypes.GUEST ? (
              <>
                <MenuItem
                  as={Button}
                  theme="secondary"
                  onClick={() => {
                    auth.saveLastAccessedPath()
                    Router.push(`/login`)
                  }}
                >
                  Log in
                </MenuItem>
                <MenuItem
                  {...menu}
                  className={`btn is-inverse inline-flex justify-center ${
                    (isReach ||
                      isConnect ||
                      userType === userTypes.COMMUNITY) &&
                    "hidden"
                  }`}
                  as={InternalLink}
                  href={{ pathname: `/community-login` }}
                >
                  Login as community
                </MenuItem>
              </>
            ) : (
              <>
                {userType !== userTypes.FAMILY_MEMBER && (
                  <>
                    <MenuItem
                      {...menu}
                      className={clsx(
                        "btn is-secondary justify-center",
                        (isReach || !currentUser.isEndUser) && "hidden"
                      )}
                      as={Button}
                      onClick={() => {
                        menu.setVisible(false)
                        Router.push(
                          `/${currentOrgSlug}/users/${currentUser.id}/detail?hideBack=true`
                        )
                      }}
                    >
                      View My Profile
                    </MenuItem>
                    <MenuItem
                      {...menu}
                      className={clsx(
                        "btn is-secondary justify-center",
                        (isReach || !currentUser.isEndUser) && "hidden"
                      )}
                      as={Button}
                      onClick={() => {
                        menu.setVisible(false)
                        Router.push(
                          `/${currentOrgSlug}/settings`
                        )
                      }}
                    >
                      Settings
                    </MenuItem>
                  </>
                )}
                <MenuItem
                  hidden={
                    currentUser.organization.isChildless ||
                    !currentUser.isEndUser
                  }
                  as={Button}
                  theme="primary"
                  onClick={() =>
                    Promise.all([
                      unsubscribeFromTopics(currentUser, organization),
                      Router.push({
                        pathname: `/${currentUser.organization.slug}/organizations`,
                        query: { parentId: currentUser.organization.id },
                      }),
                    ])
                  }
                >
                  Switch Organization
                </MenuItem>
                {userType !== userTypes.COMMUNITY && (
                  <MenuItem
                    as={Button}
                    theme="secondary"
                    onClick={handleSignOut}
                  >
                    Sign out
                  </MenuItem>
                )}
              </>
            )}
          </div>
        </div>
      </Menu>
    </div>
  )
}
