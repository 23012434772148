import { getGATrackingIDByProduct, pageView } from "app/utils/gtag"
import getConfig from "next/config"
import Router from "next/router"
import { useEffect } from "react"
import { getProductDomain } from "../Product/ProductContext"

const useGoogleAnalytics = () => {
  const { publicRuntimeConfig } = getConfig()

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (publicRuntimeConfig.GA_ENABLED && typeof window !== "undefined") {
        const productName = getProductDomain(
          window.location.hostname
        ).productDomain
        const gaTrackingID = getGATrackingIDByProduct(productName)
        if (gaTrackingID) {
          pageView(url, gaTrackingID)
        }
      }
    }

    Router.events.on("routeChangeComplete", handleRouteChange)

    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange)
    }
  }, [Router.events]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export const GoogleAnalytics = () => useGoogleAnalytics()
