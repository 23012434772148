import React, { useCallback, useContext, useState } from "react"
import { useDialogState } from "reakit/Dialog"
import { NotificationBody } from "app/features/notification/NotificationBody"
import { updateEventSubscribeTopics } from "app/lib/firebase"
import Router from "next/router"
import moment from "moment"
import { EventState } from "app/features/events/EventUtils"

const NotificationContext = React.createContext(null)

const NotificationProvider = ({ children }) => {
  const dialog = useDialogState({ modal: false })

  const [notification, setNotification] = useState(null)
  const [startedFrom] = useState(moment().subtract(15, "days").format())
  const [startedUntil] = useState(moment().add(15, "days").format())

  const showNotification = useCallback(
    (firebaseNotification) => {
      const { title } = firebaseNotification.notification
      if(firebaseNotification.notification.type === "AvailablePositionEvent") {
        updateEventSubscribeTopics([ { "id": firebaseNotification.notification.id, "state": EventState.REGISTERED } ])
      }

      if (firebaseNotification.notification.tag) {
        let translatedUrl = firebaseNotification.notification.tag

        setNotification({
          bodyElement: (
            <NotificationBody
              title={title?.split("-")[1]}
              isAlert={title.toLowerCase().includes("alert")}
              notification={firebaseNotification.notification}
              onViewDetail={() => {
                Router.push(translatedUrl)
                dialog.hide()
              }}
            />
          ),
        })

        dialog.show()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNotification, dialog, notification]
  )

  const closeNotification = useCallback(() => {
    dialog.hide()
    setNotification(null)
  }, [dialog])

  return (
    <NotificationContext.Provider
      value={{
        dialogState: dialog,
        close: closeNotification,
        showNotification,
        notification,
        startedFrom,
        startedUntil,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => useContext(NotificationContext)

export default NotificationProvider
