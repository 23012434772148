import React from "react"

export const useImportSvg = (icon) => {
  const [importedIcon, setImportedIcon] = React.useState(null)
  const IconElement = importedIcon?.[icon] || null

  const fetchIcon = async (icon) =>
    (await import(`../../common/icons/menu/${icon}.js`)).default

  React.useEffect(() => {
    if (!icon) return
    const downloadIcon = async () => {
      const downloadedIcon = await fetchIcon(icon)
      setImportedIcon({ ...importedIcon, [icon]: downloadedIcon })
    }
    downloadIcon()
  }, [icon]) // eslint-disable-line react-hooks/exhaustive-deps

  return IconElement
}
