import { useState, useEffect } from "react"

export function useOnline() {
  const [online, setOnline] = useState(
    typeof window === "undefined" || window.navigator.onLine
  )

  useEffect(() => {
    const onOnline = () => setOnline(true)
    const onOffline = () => setOnline(false)

    window.addEventListener("online", onOnline)
    window.addEventListener("offline", onOffline)

    return () => {
      window.removeEventListener("online", onOnline)
      window.removeEventListener("offline", onOffline)
    }
  }, [])

  return online
}

export const Online = ({ children }) => children(useOnline())
