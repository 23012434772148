import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import { Formik, Form } from "formik"
import yup from "app/lib/yup.validations"
import FormField from "app/common/form/FormField"
import MoodInput from "./form/MoodInput"
import { useOrganization } from "app/common/Organization"
import CheckinCompletedStep from "./form/CheckinCompletedStep"
import { useMoodCheckinState } from "./MoodCheckinContext"
import { Text } from "app/wellzesta-ui"

const createCheckinMutation = gql`
  mutation CreateCheckin($input: CheckinInput!, $organizationId: ID!) {
    createCheckin(input: $input, organizationId: $organizationId) {
      checkedOn
      lastCheckinAt
      mood
      id
    }
  }
`

export default function MoodCheckinPage(props) {
  const { finishCheckin } = props
  const [currentStep, setCurrentStep] = useState("form")
  const [mood, setMood] = useState(null)
  const { status } = useMoodCheckinState()

  useEffect(() => {
    if (status === "answering") {
      setCurrentStep("form")
    }
  }, [status])

  return (
    <div data-test-id="mood-checkin" className="pb-3 px-3 flex items-center">
      <div className="w-full" hidden={currentStep !== "form"}>
        <MoodForm setCurrentStep={setCurrentStep} setMood={setMood} />
      </div>
      <div hidden={currentStep !== "feedback"} className="w-full">
        <CheckinCompletedStep mood={mood} finishCheckin={finishCheckin} />
      </div>
    </div>
  )
}

const MoodFormSchema = yup.object().shape({
  mood: yup.string(),
})

function MoodForm({ setCurrentStep, setMood }) {
  const organization = useOrganization()
  const [createMoodCheckin] = useMutation(createCheckinMutation)
  const { setLastCheckinAt } = useMoodCheckinState()

  const onSubmit = (values) => {
    return createMoodCheckin({
      variables: {
        organizationId: organization.id,
        input: { mood: values.mood },
      },
    }).then(({ data }) => {
      setMood(values.mood)
      setCurrentStep("feedback")
      setLastCheckinAt(data?.createCheckin?.lastCheckinAt)
    })
  }

  return (
    <div>
      <Text size="md" className="block font-semibold mb-4">
        How are you feeling today?
      </Text>
      <Formik
        initialValues={{ mood: "" }}
        onSubmit={(values, actions) => {
          onSubmit(values).then(() => {
            actions.setSubmitting(false)
          })
        }}
        validationSchema={MoodFormSchema}
      >
        {() => (
          <Form>
            <FormField name="mood" component={MoodInput} />
          </Form>
        )}
      </Formik>
    </div>
  )
}
