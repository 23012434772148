import React from "react"

const Logo = ({ className, style, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    className={className}
    style={style}
    onClick={onClick}
  >
    <defs>
      <path id="a" d="M0 .475v14.904h10.305V.475H0z" />
      <path id="c" d="M.256 10.668h14.947V.393H.256v10.275z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 24.459)">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          fill="#6BBF71"
          d="M9.024 4.672C8.53 2.363 6.027.475 3.46.475.892.475-.57 3.465.21 7.122c.78 3.655 7.49 9.738 9.304 7.928 1.817-1.81.002-8.069-.49-10.378"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#0CB14B"
        d="M15.926 38.554c-1.897-1.285-4.934-.856-6.75.954-1.815 1.81-.843 4.956 2.16 6.992 3.004 2.035 12.459 1.605 12.459-.955s-5.972-5.706-7.869-6.991"
      />
      <path
        fill="#3988C8"
        d="M37.793 33.116c-1.288 1.89-.858 4.92.958 6.73 1.815 1.81 4.97.84 7.012-2.154 2.041-2.995 1.61-12.422-.958-12.422-2.567 0-5.723 5.955-7.012 7.846"
      />
      <g transform="translate(23.54 38.327)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="#44C7F3"
          d="M4.466 1.67C2.15 2.162.256 4.659.256 7.219s3 4.019 6.666 3.241c3.666-.777 9.767-7.468 7.951-9.278-1.815-1.81-8.091-.002-10.407.49"
          mask="url(#d)"
        />
      </g>
      <path
        fill="#A46AAC"
        d="M31.476.743c-3.667-.778-6.667.68-6.667 3.24s1.895 5.056 4.211 5.548c2.315.49 8.592 2.3 10.406.49 1.816-1.81-4.284-8.5-7.95-9.278"
      />
      <path
        fill="#0071BB"
        d="M46.439 12.848c-2.042-2.994-5.197-3.964-7.012-2.153-1.815 1.81-2.246 4.838-.958 6.73 1.29 1.89 4.445 7.845 7.013 7.845 2.568 0 2.998-9.427.957-12.422"
      />
      <path
        fill="#F6931D"
        d="M20.598 9.53c2.316-.49 4.211-2.987 4.211-5.546 0-2.56-3-4.02-6.667-3.241-3.666.777-9.767 7.467-7.951 9.278 1.816 1.81 8.092 0 10.407-.49"
      />
      <path
        fill="#EC1D24"
        d="M10.472 16.75c1.289-1.891.859-4.92-.958-6.73-1.815-1.81-4.97-.84-7.011 2.154C.46 15.168.892 24.596 3.46 24.596c2.567 0 5.723-5.955 7.012-7.846"
      />
      <path
        fill="#6BBF71"
        d="M20.604 25.724c-.108-.507-.66-.922-1.223-.922-.565 0-.886.657-.715 1.462.172.803 1.647 2.14 2.046 1.742.4-.398 0-1.774-.108-2.282"
      />
      <path
        fill="#0CB14B"
        d="M22.197 27.797c-.418-.283-1.085-.19-1.485.21-.399.398-.185 1.09.475 1.537.661.448 2.74.353 2.74-.21s-1.314-1.255-1.73-1.537"
      />
      <path
        fill="#3988C8"
        d="M27.005 26.6c-.283.417-.189 1.082.21 1.48.4.399 1.094.186 1.543-.472.448-.66.353-2.732-.211-2.732-.565 0-1.259 1.31-1.542 1.725"
      />
      <path
        fill="#44C7F3"
        d="M24.852 28.114c-.509.109-.925.657-.925 1.22s.66.884 1.466.712c.806-.17 2.147-1.641 1.748-2.04-.4-.398-1.78 0-2.289.108"
      />
      <path
        fill="#A46AAC"
        d="M25.764 19.482c-.806-.17-1.466.15-1.466.713 0 .562.417 1.112.926 1.22.51.108 1.89.505 2.29.108.398-.399-.943-1.87-1.75-2.041"
      />
      <path
        fill="#0071BB"
        d="M28.98 22.144c-.448-.659-1.142-.871-1.541-.473-.4.398-.495 1.063-.211 1.48.284.415.978 1.724 1.542 1.724.565 0 .66-2.073.21-2.731"
      />
      <path
        fill="#F6931D"
        d="M23.075 21.414c.51-.107.926-.657.926-1.22 0-.562-.66-.883-1.466-.712-.806.17-2.147 1.642-1.748 2.04.399.398 1.779 0 2.288-.108"
      />
      <path
        fill="#EC1D24"
        d="M20.848 23.002c.283-.415.189-1.082-.21-1.479-.4-.398-1.093-.186-1.542.473-.449.658-.355 2.731.21 2.731s1.26-1.31 1.542-1.725"
      />
      <path
        fill="#D8C4DF"
        d="M31.128 28.648c-.658 1.01-.438 2.59.487 3.514.926.923 2.535.37 3.576-1.229 1.04-1.598.821-6.204-.488-6.204-1.31 0-2.918 2.91-3.575 3.919"
      />
      <path
        fill="#7DA7D8"
        d="M31.924 22.647c.219 1.146 1.47 2.082 2.779 2.082 1.31 0 2.096-1.483 1.75-3.297-.348-1.813-3.912-5.057-4.838-4.134-.925.923.09 4.204.309 5.349"
      />
      <path
        fill="#F59679"
        d="M22.073 16.747c1.148-.219 2.088-1.465 2.088-2.77 0-1.306-1.488-2.091-3.306-1.745-1.82.346-5.074 3.9-4.147 4.823.925.923 4.217-.09 5.365-.308"
      />
      <path
        fill="#44C7F3"
        d="M28.43 17.784c1.012.656 2.597.437 3.524-.486.925-.923.37-2.527-1.232-3.565-1.604-1.038-6.222-.819-6.223.486 0 1.306 2.918 2.91 3.93 3.565"
      />
      <path
        fill="#7DA7D8"
        d="M12.739 30.784c1.04 1.6 2.65 2.151 3.576 1.229.925-.923 1.144-2.505.487-3.514-.658-1.01-2.266-3.918-3.575-3.918-1.309 0-1.529 4.605-.488 6.203M11.505 20.825c-.347 1.813.44 3.297 1.75 3.297 1.309 0 2.559-.937 2.778-2.083.22-1.144 1.235-4.426.31-5.349-.927-.923-4.492 2.321-4.838 4.135"
      />
      <path
        fill="#F6931D"
        d="M19.988 31.675c-1.013-.655-2.599-.436-3.524.487-.925.923-.371 2.527 1.231 3.565 1.604 1.037 6.223.818 6.223-.487 0-1.306-2.917-2.91-3.93-3.565"
      />
      <path
        fill="#F59679"
        d="M26.25 32.376c-1.148.218-2.088 1.464-2.088 2.77s1.488 2.09 3.306 1.745c1.82-.346 5.073-3.9 4.147-4.823-.925-.924-4.216.09-5.365.308"
      />
    </g>
  </svg>
)

export default Logo
