import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client"
import { InMemoryCache } from "@apollo/client/cache"
import { onError as errorLink } from "@apollo/client/link/error"
import { RetryLink } from "@apollo/client/link/retry"
import { createAuthenticationLink } from "app/lib/auth"
import fetch from "isomorphic-unfetch"
import getConfig from "next/config"
import possibleTypes from "./possibleTypes.json"

const { publicRuntimeConfig } = getConfig()

let apolloClient = null

// Polyfill fetch() on the server (used by apollo-client)
if (typeof window === "undefined") {
  global.fetch = fetch
}

function create(initialState, { cookies, url, ctx }) {
  let errorHandler = () => {}
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient
  const client = new ApolloClient({
    connectToDevTools: typeof window !== "undefined",
    ssrMode: typeof window === "undefined", // Disables forceFetch on the server (so queries are only run once)
    link: ApolloLink.from([
      new RetryLink(),
      createAuthenticationLink({ cookies, url, ctx }),
      errorLink((error) => errorHandler(error)),
      new HttpLink({
        uri: publicRuntimeConfig.GRAPHQL_API_URL,
        credentials: "same-origin",
      }),
    ]),
    cache: new InMemoryCache({ possibleTypes }).restore(initialState || {}),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  })

  client.onError = (handler) => {
    errorHandler = handler
  }

  return client
}

export default function initApollo(initialState, options) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === "undefined") {
    return create(initialState, options)
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState, options)
  }

  return apolloClient
}
