import { gql, useQuery } from "@apollo/client"
import moment from "moment"
import { useOrganization } from "app/common/Organization"
import { useCurrentUser } from "app/lib/CurrentUser"

export const userMonthlyQuery = gql`
  query UserMonthlyScore(
    $organizationId: ID!
    $userId: ID!
    $startedFrom: Time!
    $startedUntil: Time!
    $wellnessDimensionId: ID
    $goalYear: Int
    $goalMonth: Int
  ) {
    organization(id: $organizationId) {
      id
      user(id: $userId) {
        id
        goal(year: $goalYear, month: $goalMonth)
        monthlyScore(
          organizationId: $organizationId
          startedFrom: $startedFrom
          startedUntil: $startedUntil
          wellnessDimensionId: $wellnessDimensionId
          includeAverage: true
        ) {
          pointsAwarded
          averagePointsAwarded
          startedFrom
        }
      }
    }
  }
`

export default function useUserMonthlyScoreQuery({
  startedFrom,
  startedUntil = startedFrom,
}) {
  const organization = useOrganization()
  const currentUser = useCurrentUser()

  const { data, ...rest } = useQuery(userMonthlyQuery, {
    variables: {
      organizationId: organization.id,
      userId: currentUser.id,
      startedFrom: moment(startedFrom).startOf("month").format("YYYY-MM-DD"),
      startedUntil: moment(startedUntil).endOf("month").format("YYYY-MM-DD"),
      goalYear: moment(startedFrom).year(),
      goalMonth: moment(startedFrom).month(),
    },
  })

  return {
    ...data?.organization?.user,
    ...rest,
  }
}
