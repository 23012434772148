import { gql, useMutation } from "@apollo/client"

import { useEffect } from "react"

const MUTATION = gql`
  mutation ViewMessage($id: ID!, $organizationId: ID!, $type: MessageType!) {
    viewMessage(id: $id, organizationId: $organizationId, type: $type) {
      __typename
    }
  }
`

export function useLazyViewMessage() {
  return useMutation(MUTATION)
}

export default function useViewMessage({ organizationId, id, type, enabled }) {
  const [viewMessage] = useLazyViewMessage()

  useEffect(() => {
    if (enabled) {
      viewMessage({ variables: { organizationId, id, type } })
    }
  }, [id, organizationId, enabled, viewMessage, type])

  return null
}
