import React, { createContext, useState, useEffect } from "react"
/* eslint-disable no-useless-escape,no-unused-vars */
import { getFirebase } from "app/lib/firebase"
import { DEFAULT_FEATURE_FLAG_STATE } from "./Constants"
import { useCurrentUser } from 'app/lib/CurrentUser'
export const FeatureFlagsContext = createContext({})

const FeatureFlagsProvider = ({ children }) => {
  const [flags, setFlags] = useState({ ...DEFAULT_FEATURE_FLAG_STATE })
  const currentUser = useCurrentUser()

  useEffect(() => {
    let newFlagState = { ...DEFAULT_FEATURE_FLAG_STATE, ...currentUser.featureFlags }
    setFlags({...newFlagState})
  }, [currentUser.isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FeatureFlagsContext.Provider
      value={{
        featureFlags: flags,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  )
}

export default FeatureFlagsProvider
