import CryptoJS from "crypto-js"

const SECRET_KEY = process.env.SECURE_LOCAL_STORAGE_HASH_KEY || "FALLBACK_HASH_KEY_dklhdkkdhdkljhdlkjdd"

export const setItem = (key, value, expirationInMinutes) => {
  const now = new Date()

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + (60000 * expirationInMinutes),
  }
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(item), SECRET_KEY).toString();
  window.localStorage.setItem(key, encrypted)
}

export const getItem = (key) => {
  const itemStr = window.localStorage.getItem(key)

  // if the item doesn't exist, return null
  if (!itemStr) {
    return null
  }

  const decrypted = CryptoJS.AES.decrypt(itemStr, SECRET_KEY).toString(CryptoJS.enc.Utf8);
  const item = JSON.parse(decrypted)
  const now = new Date()

  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    window.localStorage.removeItem(key)
    return null
  }
  return item.value
}

export const removeItem = (key) => {
  window.localStorage.removeItem(key)
}
