import React from "react"
import TruncateWrapper from "../../common/TruncateWrapper"
import { Text } from "../text/Text"

const CardTitle = ({ lines = 2, ...props }) => {
  return (
    <Text as="h2" size="lg" className="font-bold">
      <TruncateWrapper lines={lines} {...props} />
    </Text>
  )
}

export default CardTitle
