import React, { useEffect } from "react"
import { useDialogState, Dialog } from "reakit/Dialog"
import { Text } from "app/wellzesta-ui"
import { useOnline } from "app/lib/Online"
import clsx from "clsx"
import { useMobile } from "app/lib/hooks/useBreakpoints"
import styled from "styled-components"

const OfflineDialog = (props) => {
  const { className } = props
  const dialog = useDialogState({ modal: false, animated: true })
  const online = useOnline()
  const isMobile = useMobile()

  useEffect(() => {
    if (online) {
      dialog.hide()
    } else {
      dialog.show()
    }
  }, [online, dialog])

  return (
    <Dialog
      {...dialog}
      aria-label="Internet Status"
      hideOnClickOutside={false}
      className={clsx("sticky py-2 w-full z-main bg-black", className)}
      tabIndex={0}
    >
      <div className="px-4 mx-auto md:max-w-screen-xl">
        <Text
          size="md"
          as="p"
          className="font-bold text-white"
          style={!isMobile ? { fontSize: "1.125rem" } : {}}
        >
          Your online connection is unstable.
        </Text>
        <Text size="sm" as="p" className="text-white">
          Please check your device’s connection to WiFi and try again.
        </Text>
      </div>
    </Dialog>
  )
}

const StyledOfflineDialog = styled(OfflineDialog)`
  transition: opacity 250ms ease-in-out, transform 250ms ease-in-out;
  opacity: 0;

  &[data-enter] {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

export default StyledOfflineDialog
