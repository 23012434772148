import React from "react"
import { Query as ApolloQuery } from "@apollo/client/react/components"

function Query({ children, ...props }) {
  return (
    <ApolloQuery {...props} notifyOnNetworkStatusChange>
      {(state) => {
        return children(state)
      }}
    </ApolloQuery>
  )
}

export default Query
