import React from "react"
import { useRouter } from "next/router"
import clsx from "clsx"
import { Heading, Box, Text, Button } from "app/wellzesta-ui"
import Logo from "app/common/Logo"
import { useOnline } from "app/lib/Online"
import { useLazyQuery } from "@apollo/client"
import { QUERY as currentUserQuery } from "app/lib/CurrentUser"
import LifeLogo from "app/common/icons/LifeLogo"
import { HeaderBar } from "../Header"
import MainColumn from "../MainColumn"
import styles from "./Spinner.module.css"

export default function OfflinePage() {
  const online = useOnline()
  const router = useRouter()
  const [fetchCurrentUser, { loading }] = useLazyQuery(currentUserQuery)

  const testNetworkConnection = async () => {
    if (online) {
      router.reload()
    } else {
      if (!loading) {
        const response = await fetchCurrentUser()
        if (!loading && response?.data?.currentUser) {
          router.reload()
        }
      }
    }
  }

  return (
    <>
      <HeaderBar>
        <Box className="mr-2 md:mr-3 flex-grow-0 flex-shrink-0 min-w-0">
          <Logo className="w-8" />
        </Box>
      </HeaderBar>
      <MainColumn size="md">
        <div className="flex justify-center flex-col items-center mt-40 text-center">
          <Heading size="md" as="h2" className="font-bold">
            No internet connection
          </Heading>
          <Text size="md" as="h3" marginTop="2rem">
            Check your connection and try again
          </Text>
          <div hidden={!loading} className={clsx(styles.spinner, "py-3")}>
            <LifeLogo className="w-12" />
          </div>
          <Button
            theme="tertiary"
            className="mt-6"
            onClick={testNetworkConnection}
          >
            {loading ? "Connecting..." : "Try again"}
          </Button>
        </div>
      </MainColumn>
    </>
  )
}
