import { createContext, useContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';


const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
    const router = useRouter();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [emptyResults, setEmptyResults] = useState(false);
    const [inputValue, setInputValue] = useState("");

    // Clear context and storage if accessed directly through a fresh page load
    useEffect(() => {
        const isNavigatedInApp = window.sessionStorage.getItem('navigatedInApp');

        if (!isNavigatedInApp) {
            clearSearchResults();
        }

        window.sessionStorage.setItem('navigatedInApp', 'true');
    }, [router.pathname]);


    useEffect(() => {
        const savedSearchQuery = window.localStorage.getItem('searchQuery');
        const savedSearchResults = window.localStorage.getItem('searchResults');
        const savedShowResults = window.localStorage.getItem('showResults');
        const savedInputValue = window.localStorage.getItem('inputValue');

        if (savedSearchQuery) setSearchQuery(savedSearchQuery);
        if (savedSearchResults) setSearchResults(JSON.parse(savedSearchResults));
        if (savedShowResults) setShowResults(JSON.parse(savedShowResults));
        if (savedInputValue) setInputValue(savedInputValue);
    }, []);


    useEffect(() => {
        window.localStorage.setItem('searchQuery', searchQuery);
        window.localStorage.setItem('searchResults', JSON.stringify(searchResults));
        window.localStorage.setItem('showResults', JSON.stringify(showResults));
        window.localStorage.setItem('inputValue', inputValue);
    }, [searchQuery, searchResults, showResults, inputValue]);

    const clearSearchResults = () => {
        setSearchQuery("");
        setSearchResults([]);
        setShowResults(false);
        setEmptyResults(false);
        setInputValue("");
        window.localStorage.removeItem('searchQuery');
        window.localStorage.removeItem('searchResults');
        window.localStorage.removeItem('showResults');
        window.localStorage.removeItem('inputValue');
    };


    return (
        <SearchContext.Provider
            value={{
                searchQuery,
                setSearchQuery,
                searchResults,
                setSearchResults,
                showResults,
                setShowResults,
                emptyResults,
                setEmptyResults,
                inputValue,
                setInputValue,
                clearSearchResults,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export const useSearchContext = () => useContext(SearchContext);
