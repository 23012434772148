import React from "react"

const Menu = ({ className }) => {
  return (
    <svg viewBox="0 0 48 35" className={className}>
      <rect y="0.769226" width="48" height="3" fill="#2C2C2C" />
      <path
        d="M1.01136 12.1329H4.04545L7.25 19.951H7.38636L10.5909 12.1329H13.625V23.7692H11.2386V16.1954H11.142L8.13068 23.7124H6.50568L3.49432 16.167H3.39773V23.7692H1.01136V12.1329ZM15.652 23.7692V12.1329H23.4929V14.1613H18.1122V16.934H23.0895V18.9624H18.1122V21.7408H23.5156V23.7692H15.652ZM35.1818 12.1329V23.7692H33.0568L27.9943 16.4454H27.9091V23.7692H25.4489V12.1329H27.608L32.6307 19.451H32.733V12.1329H35.1818ZM44.402 12.1329H46.8622V19.6897C46.8622 20.5382 46.6596 21.2806 46.2543 21.917C45.8527 22.5533 45.2902 23.0495 44.5668 23.4056C43.8433 23.7579 43.0005 23.934 42.0384 23.934C41.0724 23.934 40.2277 23.7579 39.5043 23.4056C38.7808 23.0495 38.2183 22.5533 37.8168 21.917C37.4152 21.2806 37.2145 20.5382 37.2145 19.6897V12.1329H39.6747V19.4795C39.6747 19.9226 39.7713 20.3166 39.9645 20.6613C40.1615 21.006 40.438 21.2768 40.794 21.4738C41.1501 21.6707 41.5649 21.7692 42.0384 21.7692C42.5156 21.7692 42.9304 21.6707 43.2827 21.4738C43.6387 21.2768 43.9134 21.006 44.1065 20.6613C44.3035 20.3166 44.402 19.9226 44.402 19.4795V12.1329Z"
        fill="black"
      />
      <rect y="31.7692" width="48" height="3" fill="#2C2C2C" />
    </svg>
  )
}

export default Menu
