import React from "react"

function MainColumn({ size = "xl", className, children, ...props }) {
  const maxWidth = {
    sm: "md:max-w-screen-sm",
    md: "md:max-w-screen-md",
    lg: "md:max-w-screen-lg",
    xl: "md:max-w-screen-xl",
  }[size]

  return (
    <div
      className={`h-full max-w-full px-4 mx-auto md:px-4 py-4 ${maxWidth} ${className}`}
      {...props}
    >
      {children}
    </div>
  )
}

export default MainColumn
