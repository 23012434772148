const Rollbar = require("rollbar")

const token = process.browser
  ? process.env.ROLLBAR_CLIENT_TOKEN
  : process.env.ROLLBAR_ACCESS_TOKEN

const rollbar = new Rollbar({
  enabled: !!token || process.env.NODE_ENV !== "production",
  accessToken: token,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: "production",
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.NEXT_BUILD_ID,
      },
    },
  },
})

rollbar.setUser = (user) => {
  rollbar.configure({ payload: { person: user } })
}

if (process.env.NODE_ENV === "development") {
  // On development we log rollbar requests so we can debug which errors are being reported without spamming the logs
  rollbar.client.queue.api.transport = {
    get(...args) {
      console.log("[rollbar]", "GET", ...args)
    },
    post(accessToken, options, payload) {
      console.log("[rollbar]", "POST", payload.data.body)
    },
  }
}

module.exports = rollbar
