import { pipe, toLower, startCase } from "lodash/fp"
import packageJson from "../../package.json"
import UAParser from "ua-parser-js"

export function formatPhone(value) {
  if (!value) return

  value = value.replace(/[^0-9]/g, "")

  if (value.length <= 7) {
    return `(${value.slice(0, 3)}) ${value.slice(3)}`
  }

  return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`
}

export function splitCamelCase(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}

export function formatEnumConstant(constant) {
  // "AS_USUAL" => "As Usual"
  return pipe(toLower, startCase)(constant)
}

export const replaceSubdomain = (url, subdomain) => {
  const hostParts = url.host.split(".")
  hostParts[0] = subdomain

  return url.protocol + "//" + hostParts.join(".") + url.pathname
}

export const getAppVersion = () => packageJson.version

export const getFullAppVersion = () => {
  const ua = new UAParser().getResult()

  return `${packageJson.version};${ua.os.name} ${ua.os.version};${ua.browser.name} ${ua.browser.version}`
}

export const isTabletOrWebviewOrPWA = () => {
  if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/iPad|Macintosh/i.test(userAgent) && 'ontouchend' in document) {
          return true;
      }

      if (/android/i.test(userAgent) && !/mobile/i.test(userAgent)) {
          return true;
      }

      // Check for common webview indicators
      const isWebview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent) ||
                        /Android.*(wv|\.0\.0\.0)/i.test(userAgent)

      if (isWebview) {
          return true;
      }

      // Check for PWA
      const isPWA = window.matchMedia('(display-mode: standalone)').matches ||
                    window.matchMedia('(display-mode: fullscreen)').matches ||
                    window.matchMedia('(display-mode: minimal-ui)').matches ||
                    navigator.standalone === true;

      if (isPWA) {
          return true;
      }

      // Check for standalone webview apps
      const isStandaloneWebviewApp = /StandaloneApp/i.test(userAgent) || 
                                     window.navigator.standalone === true ||
                                     (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches);

      if (isStandaloneWebviewApp) {
          return true;
      }
  }
  return false;
};



