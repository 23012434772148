const getReachRoutes = () => {
  return [
    {
      label: "Messages",
      href: "/[organizationSlug]/messages",
    },
    {
      label: "Events",
      href: "/[organizationSlug]/events",
    },
    {
      label: "Guide",
      items: [
        {
          label: "Documents",
          href: "/[organizationSlug]/documents",
        },
        {
          label: "Services",
          href: "/[organizationSlug]/services",
        },
        {
          label: "Connected Apps",
          href: "/[organizationSlug]/connected-apps",
        },
      ],
    },
    {
      label: "Connect",
      items: [
        {
          label: "Chat",
          href: "/[organizationSlug]/chat",
        },
        {
          label: "Contacts",
          href: "/[organizationSlug]/users",
        },
        {
          label: "Bulletin Board",
          href: "/[organizationSlug]/bulletin-boards",
        },
      ],
    },
    {
      label: "Wellness",
      items: [
        {
          label: "Articles",
          href: "/[organizationSlug]/articles",
        },
        {
          label: "Recipes",
          href: "/[organizationSlug]/recipes",
        },
        {
          label: "Videos",
          href: "/[organizationSlug]/videos",
        },
      ],
    },
  ].filter(Boolean)
}

export default getReachRoutes
