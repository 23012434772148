import React from "react"
import { useId } from "@reach/auto-id"

const Guest = ({ className }) => {
  const id = useId()
  return (
    <svg viewBox="0 0 32 32" className={className}>
      <title>Guest&apos;s profile picture</title>
      <g fill="none" fillRule="evenodd">
        <g>
          <mask id={`guest-mask-${id}`} fill="currentColor">
            <path d="M16,0 C7.168,0 0,7.168 0,16 C0,24.832 7.168,32 16,32 C24.832,32 32,24.832 32,16 C32,7.168 24.832,0 16,0 Z M16,4.8 C18.656,4.8 20.8,6.944 20.8,9.6 C20.8,12.256 18.656,14.4 16,14.4 C13.344,14.4 11.2,12.256 11.2,9.6 C11.2,6.944 13.344,4.8 16,4.8 Z M16,27.52 C12,27.52 8.464,25.472 6.4,22.368 C6.448,19.184 12.8,17.44 16,17.44 C19.184,17.44 25.552,19.184 25.6,22.368 C23.536,25.472 20,27.52 16,27.52 Z" />
          </mask>
          <g fill="currentColor" mask={`url(#guest-mask-${id})`}>
            <rect width="38" height="38" transform="translate(-3.2 -3.2)" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Guest
