import { useEffect } from "react"
import Router from "next/router"

const cacheMap = new Map()

function handleRouteChange(route) {
  const [path] = route.split("?")
  cacheMap.set(path, route)
}

export default function CacheProvider({ children }) {
  useEffect(() => {
    Router.events.on("routeChangeStart", handleRouteChange)
    return () => Router.events.off("routeChangeStart", handleRouteChange)
  }, [])

  return children
}

export function getCachedRoute(path) {
  // Only return cached routes in browser env to avoid shared state on SSR
  if (typeof window !== "undefined") {
    return cacheMap.get(path)
  }
}
