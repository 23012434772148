import { AuthService } from "./AuthService"
import { ApolloLink } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import getConfig from "next/config"

const { publicRuntimeConfig } = getConfig()
const WZ_APP_VERSION = publicRuntimeConfig.WZ_APP_VERSION

const isNotAuthorizedError = (graphQLErrors) =>
  graphQLErrors?.some((e) => e.type === "NotAuthorized")

export const createAuthenticationLink = ({ cookies, url, ctx }) => {
  const auth = new AuthService({ cookies, url })
  return ApolloLink.from([
    new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          authorization: auth.getToken() || "",
          'wz-app-version': WZ_APP_VERSION
        },
      })

      return forward(operation)
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
        if (isNotAuthorizedError(graphQLErrors)) {
          if (ctx.pathname !== "/login") {
            if (typeof window !== "undefined") {
              auth.logout()
              auth.login()
            }
          }
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
      }
    }),
  ])
}
