import moment from "moment"

export enum EventState {
  WAITLIST = "WAIT LIST",
  WAITING = "WAITING",
  REGISTERED = "REGISTERED",
  REGISTRATION_CLOSED = "REGISTRATION CLOSED",
  COMING_SOON = "Coming Soon",
  Register = "Register"
}

export function getEventState(event): EventState {
  if (event.registrationLimit) {
    const currentDate = moment();
    const registrationEnded = moment(event.registrationEndedAt);

    if (registrationEnded.isBefore(currentDate)) {
      if (!event.registered && event.full) {
        return EventState.WAITLIST;
      }

      if (event.registered && event.onWaitlist) {
        return EventState.WAITING;
      }

      if (event.registered && !event.onWaitlist) {
        return EventState.REGISTERED;
      }

      return EventState.REGISTRATION_CLOSED;
    }

    if (event.registrationStartedAt) {
      const registrationStarted = moment(event.registrationStartedAt);

      if (currentDate.isBefore(registrationStarted)) {
        return EventState.COMING_SOON;
      }
    }
  }

  // Positions
  if (event.unlimitedPositions) {
    if (event.registered) {
      return EventState.REGISTERED;
    }
    return EventState.Register;
  } else {
    if (!event.registered && event.full) {
      return EventState.WAITLIST;
    }
    if (!event.registered && !event.full) {
      return EventState.Register;
    }
    if (event.registered && event.onWaitlist) {
      return EventState.WAITING;
    }
    if (event.registered && !event.onWaitlist) {
      return EventState.REGISTERED;
    }
  }
}