import { userTypes } from "app/lib/CurrentUser"
import getConfig from "next/config"

const getLifeRoutes = ({
  currentUser,
  emcOrganization,
  isElevate,
  isConnect,
}) => {
  const showMyProgress = [userTypes.DEFAULT].includes(currentUser.userType)
  const guideLabel = isElevate ? "Services" : "Concierge"

  const { publicRuntimeConfig } = getConfig()
  let activeUrl;
  if (publicRuntimeConfig.RUNNING_ENV !== "staging") {
    activeUrl = "https://active.wellzesta.com"
  } else {
    activeUrl = "https://active.stg.wellzesta.com"
  }

  return [
    {
      label: "Home",
      href: "/[organizationSlug]/home",
      icon: "Home",
    },
    {
      label: "Messages",
      href: "/[organizationSlug]/messages",
      icon: "Message",
    },
    {
      label: "Events",
      href: "/[organizationSlug]/events",
      icon: "Event",
    },
    !isElevate && {
      label: "Dining",
      icon: "Dining",
      items: emcOrganization
        ? [
            {
              label: "Menus",
              href: "/[organizationSlug]/meals",
            },
          ]
        : [
            {
              label: "Menus",
              href: "/[organizationSlug]/meals",
            },
            {
              label: "Venues",
              href: "/[organizationSlug]/dining-venues",
            },
            {
              label: "FullCount",
              href: `${activeUrl}/oauth/authorize?response_type=code&client_id=${publicRuntimeConfig.FULL_COUNT_CLIENT_ID}`,
            },
          ],
    },

    currentUser.userType !== userTypes.FAMILY_MEMBER && {
      label: "Connect",
      icon: "Connect",
      items: [
        {
          label: "Directory",
          href: "/[organizationSlug]/users",
        },
        {
          label: "Groups",
          href: "/[organizationSlug]/groups",
        },
        ...(!isConnect ? [
          {
            label: "Bulletin Board",
            href: "/[organizationSlug]/bulletin-boards",
          }
        ] : []),
      ],
    },
    currentUser.userType !== userTypes.COMMUNITY && !isConnect && {
      label: "Resources",
      icon: "Resource",
      items: [
        {
          label: guideLabel,
          href: "/[organizationSlug]/guide",
        },
        {
          label: "Documents",
          href: "/[organizationSlug]/documents",
        },
        {
          label: "App Support",
          href: "/[organizationSlug]/app-support",
        },
      ],
    },
    currentUser.userType === userTypes.COMMUNITY && !isConnect && {
      label: "Resources",
      icon: "Resource",
      items: [
        {
          label: guideLabel,
          href: "/[organizationSlug]/guide",
        },
        {
          label: "Documents",
          href: "/[organizationSlug]/documents",
        },
      ],
    },
    {
      label: "Well-being",
      icon: "Wellbeing",
      href: "/[organizationSlug]/well-being",
    },
    !isElevate &&
      showMyProgress && {
        label: "My Space",
        icon: "Profile",
        items: [
          {
            label: "Service Requests Log",
            href: "/[organizationSlug]/service-requests",
          },
          {
            label: "Progress",
            href: "/[organizationSlug]/progress",
          },
          {
            label: "Profile",
            href: `/[organizationSlug]/users/${currentUser.id}/detail?hideBack=true`,
          },
        ],
      },
    !isElevate &&
      currentUser.userType === userTypes.FAMILY_MEMBER && {
        label: "My Space",
        icon: "Profile",
        items: [
          {
            label: "Service Requests Log",
            href: "/[organizationSlug]/service-requests",
          },
          {
            label: "Progress",
            href: "/[organizationSlug]/progress",
          },
        ],
      },
    isElevate && {
      label: "Profile",
      icon: "Profile",
      href: `/[organizationSlug]/users/${currentUser.id}/detail?hideBack=true`,
    },
  ].filter(Boolean)
}

export default getLifeRoutes
