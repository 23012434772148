import React from "react"
import { theme } from "styled-tools"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100px;
  align-items: center;
`
const StyledMessage = styled.div`
  font-weight: ${theme("fontWeight.semibold")};
  font-size: 16px;
  letter-spacing: 0.9px;
`
export default function PageMessage({ children }) {
  return (
    <Container>
      <StyledMessage>{children}</StyledMessage>
    </Container>
  )
}
